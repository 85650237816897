import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Paragraph from "../elements/paragraph"
import Pullquote from "../elements/pullquote"
import ContentWrapper from "../elements/content-wrapper"
import Heading from "../elements/heading"
import Separator from "../elements/separator"
import RedBoxLg from "../elements/red-box-lg"
import Img from "gatsby-image";
import NameTitle from "../elements/name-title"

function Section2Component(props) {

    const theme = useTheme();

    const Section2 = styled.section`
        position: relative;
        margin-top: -170px;
        padding-top: 150px;
        padding-bottom: 10px;
        z-index: 0;
        background: #FFFFFF;
        @media (min-width: ${theme.screens.mobile}) {
            padding-top: 360px;
            margin-top: -380px;
            padding-bottom: 100px;
        }
    `
    const pullquote = css`
        width: 75%;
        background: #FFF;
        margin-bottom: 30px;
        position: relative;
        z-index: 2;
        @media (min-width: ${theme.screens.mobile}) {
            width: 100%;
            margin-bottom: 40px;
        }
        @media (min-width: 1220px) {
            width: calc(100% + 40px);
        }
    `
    const leftCol = css`
        flex-basis: 370px;
        margin-bottom: 30px;
        margin-right: 65px;
        @media (min-width: ${theme.screens.mobile}) {
            margin-bottom: 0px;
        }
    `
    const rightCol = css`
        flex-basis: 680px;
        margin-bottom: 10px;
        @media (min-width: ${theme.screens.mobile}) {
            margin-bottom: 0px;
        }
    `
    const redBox = css`
        max-width: 936px;
        margin: auto;
    `
    const paragraph = css`
        width: 100%;
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(100% - 80px);
        }
        @media (min-width: 1220px) {
            width: calc(100% - 160px);
        }
    `
    const headshot = css`
        width: 280px;
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: -40px;
        @media (min-width: ${theme.screens.mobile}) {
            width: 100%;
            margin-top: 0px;
            margin-left: 0px;
        }
    `
    const contentInner = css`
        @media (max-width: ${theme.screens.mobile}) {
            padding: 20px;
        }
    `

    const nameTitle = css`
        margin-left: 0px;
        @media (min-width: ${theme.screens.mobile}) {
            margin-left: 50px;
        }
        @media (min-width: 1220px) {
            margin-left: 0px;   
        }
    `

    const Section2Inner = styled.div`
        display: flex;
        width: 100%;
        margin-left: 0px;
        flex-wrap: wrap;
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(100% + 220px);
            margin-left: -110px;
            flex-wrap: nowrap;
        }
        @media (min-width: 1220px) {
            width: calc(100% + 80px + 100px + 80px + 100px);
            margin-left: calc(-80px - 100px);
        }
    `

    return (

        <StaticQuery query={graphql`
        query {
          headshot: file(relativePath: {
            eq: "1_Intro/Susan_Domchek_Intro.jpg"
          }) {
            childImageSharp {
              fluid(quality: 75, maxWidth: 360) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }`
        } render={data => {
            const imageData = data.headshot.childImageSharp.fluid;
            return (
                <Section2 {...props}>
                    <ContentWrapper css={contentInner}>
                        <Heading>Basser Center Takes Aim at BRCA</Heading>
                        <Separator color="red"></Separator>
                        <RedBoxLg css={redBox}>
                            <Section2Inner>
                                <div css={leftCol}>
                                    <Img css={headshot} fluid={imageData} />
                                    <NameTitle size="large" css={nameTitle} name="SUSAN M. DOMCHEK, MD" title="Basser Professor in Oncology<br>Basser Center Executive Director" />
                                </div>
                                <div css={rightCol}>
                                    <Pullquote css={pullquote}>
                                        At Basser, 25 million people affected means 25 million opportunities for a cure.
                                    </Pullquote>
                                    <Paragraph css={paragraph}>
                                        The discovery of <i>BRCA1</i> and <i>BRCA2</i> sparked incredible innovation in cancer research. The Basser Center at Penn Medicine’s Abramson Cancer Center turned that initial spark into a flame of promise — a focus on ensuring that future generations have better options. Basser is committed to collaborative science and compassionate clinical care. We know that approximately 25 million people are affected worldwide, but only 10% are aware of their mutation status. By raising awareness, Basser is saving lives. While our innovative research starts at Penn, it extends to best-in-class institutions around the world. This collective spirit has led to unparalleled collaboration and will push us to faster progress.
                                        <br /><br />
                                        Our goal is to engage as many esteemed partners as possible in this mission. This collaborative approach is revolutionizing the field, expanding genetic testing, and spreading life-saving information to high-risk populations.
                                    </Paragraph>
                                </div>
                            </Section2Inner>
                        </RedBoxLg>
                    </ContentWrapper>
                </Section2>
            )
        }
        } />
    )
}

export default Section2Component;