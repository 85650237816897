import React from "react";
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import ContentWrapper from "../elements/content-wrapper"
import Heading from "../elements/heading"
import Separator from "../elements/separator"
import DonutGraph from "../elements/donut-graph"
import { fluidRange } from 'polished';

function ByTheNumbers(props) {

    const theme = useTheme();

    const ByTheNumbers = styled.section`
        background-color: ${props => props.theme.colors.offWhite};
        padding-bottom: 70px;
        @media (min-width: ${props => props.theme.screens.mobile}) {
            padding-bottom: 100px;
        }
    `

    const StatsBox = styled.div`
        background: ${props => props.theme.colors.teal};
        padding: 40px;
        width: 100%;
        @media (min-width: ${theme.screens.mobile}) {
            flex-basis: 400px;
        }
        ${fluidRange({
            prop: 'padding',
            fromSize: '20px',
            toSize: '40px'
          }, theme.screens.mobile, theme.screens.desktop)}      
    `

    const GraphBox = styled.div`
        width: 100%;
        flex-basis: 850px;
        display: flex;
        align-items: center;
        ${fluidRange({
            prop: 'padding',
            fromSize: '20px',
            toSize: '70px'
          }, theme.screens.mobile, theme.screens.desktop)}      
    `

    const GraphBoxInner = styled.div`
        width: 100%;
    `

    const NumbersBox = styled.div`
        width: 100%;
        background: #FFFFFF;
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        border: 1px solid #127897;
        box-shadow: ${theme.shadow};
        @media (min-width: ${theme.screens.mobile}) {
            flex-wrap: nowrap;
        }
    `

    const GraphHeading = styled.h3`
        width: 100%;
        display: block;
        font-size: 24px;
        font-weight: 200;
        color: ${props => props.theme.colors.blue};
        position: relative;
        overflow: hidden;
        margin: 0px 0px 20px 0px;
        text-align: center;
        @media (min-width: ${theme.screens.mobile}) {
            font-size: 34px;
            text-align: left;
            margin: 0px 0px 40px 0px;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background: ${props => props.theme.colors.red};
                z-index: 1;
                top: 0px;
                bottom: 0px;
                margin: auto;
            }
            & span {
                padding-right: 30px;
                background: #FFF;
                display: inline-block;
                position: relative;
                z-index: 5;
            }    
        }
    `

    const DonutGraphs = styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    `

    const DonutGraphWrapper = styled.div`
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 0px;
        }
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(50% - 10px);
            margin-bottom: 70px;
            &:nth-last-child(2) {
                margin-bottom: 0px;
            }    
        }
        @media (min-width: ${theme.screens.desktop}) {
            width: calc(50% - 40px);
        }
    `

    const DonutGraphCaption = styled.p`
        color: ${props => props.theme.colors.blue};
        font-size: 16px;
        font-weight: 200;
        width: 100%;
        margin-left: 12px;
        @media (min-width: ${theme.screens.mobile}) {
            width: 50%;
            margin-left: 25px;
        }
    `

    const Stat = ({ heading, paragraph }) => {

        const StatWrap = styled.div`
            text-align: center;
            border-bottom: 3px solid #FFF;
            padding: 35px 20px;
            &:last-of-type {
                border-bottom: none;
            }
        `

        const StatHeading = styled.h3`
            font-weight: 900;
            color: #FFF;
            margin: 0px;
            ${fluidRange({
                prop: 'font-size',
                fromSize: '40px',
                toSize: '50px'
            }, theme.screens.mobile, theme.screens.desktop)}      
        `

        const StatBody = styled.p`
            font-size: 20px;
            line-height: 24px;
            font-weight: normal;
            color: #FFF;
            margin: 0px;
        `

        return (
            <StatWrap>
                <StatHeading>{heading}</StatHeading>
                <StatBody>{paragraph}</StatBody>
            </StatWrap>
        )
    };

    return (
        <ByTheNumbers>
            <Heading>BRCA by the Numbers</Heading>
            <Separator color="red"></Separator>
            <ContentWrapper>
                <NumbersBox>
                    <StatsBox>
                        <Stat heading="1 in 300" paragraph="people in the general population are estimated to have a BRCA mutation"></Stat>
                        <Stat heading="1 in 40" paragraph="people in the Ashkenazi Jewish population have a BRCA mutation"></Stat>
                        <Stat heading="1 in 7" paragraph="women with ovarian cancer have a BRCA mutation"></Stat>
                    </StatsBox>
                    <GraphBox>
                        <GraphBoxInner>
                            <GraphHeading>
                                <span>Increased Risk of Cancer</span>
                            </GraphHeading>
                            <DonutGraphs>
                                <DonutGraphWrapper>
                                    <DonutGraph color={theme.colors.aqua} label="7%" value={0.07} />
                                    <DonutGraphCaption>
                                        Men and women with a <i>BRCA2</i> mutation have up to a 7% lifetime risk of pancreatic cancer
                                    </DonutGraphCaption>
                                </DonutGraphWrapper>
                                <DonutGraphWrapper>
                                    <DonutGraph color={theme.colors.teal} label="25%" value={0.25} />
                                    <DonutGraphCaption>
                                        Men with a <i>BRCA2</i> mutation have up to a 25% lifetime risk of aggressive prostate cancer
                                    </DonutGraphCaption>
                                </DonutGraphWrapper>
                                <DonutGraphWrapper>
                                    <DonutGraph color={theme.colors.blue} label="50%" value={0.5} />
                                    <DonutGraphCaption>
                                        Women with a <i>BRCA1</i> mutation have up to a 50% lifetime risk of ovarian cancer
                                    </DonutGraphCaption>
                                </DonutGraphWrapper>
                                <DonutGraphWrapper>
                                    <DonutGraph color={theme.colors.red} label="75%" value={0.75} />
                                    <DonutGraphCaption>
                                        Women with a <i>BRCA1</i> or <i>BRCA2</i> mutation have up to a 75% lifetime risk of breast cancer 
                                    </DonutGraphCaption>
                                </DonutGraphWrapper>
                            </DonutGraphs>
                        </GraphBoxInner>
                    </GraphBox>
                </NumbersBox>
            </ContentWrapper>
        </ByTheNumbers>
    )
}

export default ByTheNumbers;