import React, { useEffect } from "react"
import styled from '@emotion/styled'
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import { fluidRange } from 'polished';

const DonutGraphWrapper = styled.div`
    position: relative;
    width: 135px;
    flex-basis: 135px;
    & svg {
        display: block;
    }
`

const OverlayWrapper = styled.div`
    margin: auto;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const OverlayText = styled.h5`
    font-weight: 200;
    display: inline-block;
    color: ${props => props.theme.colors.blue};
    margin: 0px;
    ${props => fluidRange({
        prop: 'font-size',
        fromSize: '28px',
        toSize: '46px'
      }, props.theme.screens.mobile, props.theme.screens.desktop)}      
`

function DonutGraphComponent({ label, value, color }) {
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        controls.start(() => ({
            pathLength: value, 
            pathOffset: 0
        }));
      }
    }, [controls, inView]);
    
    return (
        <DonutGraphWrapper>
            <OverlayWrapper>
                <OverlayText>
                    {label}
                </OverlayText>
            </OverlayWrapper>
            <svg ref={ref} className="progress-icon" viewBox="0 0 50 50">
                <path
                    fill="none"
                    strokeWidth="3"
                    stroke="#DDDDDD"
                    strokeDasharray="10px"
                    d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                    pathLength="1"
                    pathOffset="0"
                    style={{
                        transform: "translateX(5px) translateY(5px)"
                    }}
                />
                <motion.path
                    fill="none"
                    strokeWidth="5"
                    stroke={color}
                    strokeDasharray="0 1"
                    d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                    initial={{ pathLength: 0, pathOffset: 0 }}
                    animate={controls}
                    transition={{ duration: 2 }}
                    style={{
                        rotate: 90,
                        translateX: 5,
                        translateY: 5,
                        scaleX: -1
                    }}
                />
            </svg>
        </DonutGraphWrapper>
    )
}

export default DonutGraphComponent;