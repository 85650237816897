import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Paragraph from "../elements/paragraph"
import ContentWrapper from "../elements/content-wrapper"
import Video from "../elements/video"

function Section1Component(props) {

    const theme = useTheme();

    const Section1 = styled.div`
        width: 100%;
        background: #F7F7F7;
        margin-bottom: 60px;
        @media (min-width: ${theme.screens.mobile}) {
            margin-bottom: 120px;
        }
    `
    const paragraphStyles = css`
        text-align: center;
        max-width: 762px;
        margin: auto;
        padding: 60px 0px 60px 0px;
        display: block;
        opacity: 0;
        @media (min-width: ${theme.screens.mobile}) {
            padding: 130px 0px 60px 0px;
        }
    `

    const videoStyles = css`
        margin: auto;
        box-shadow: ${theme.shadow};
        z-index: 2;
        position: relative;
    `

    return (

        <StaticQuery query={graphql`
        query {
          video: file(relativePath: {
            eq: "1_Intro/Video.jpg"
          }) {
            childImageSharp {
              fluid(quality: 75, maxWidth: 807) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }`
        } render={data => {
            const imageData = data.video.childImageSharp.fluid;
            return (
                <Section1 {...props}>
                    <ContentWrapper>
                        <Paragraph animateFrom={{opacity: 0, scale: 0.95}} animateTo={{opacity: 1, scale: 1}} transition={{delay: 0, duration: 1}} css={paragraphStyles}>The BRCA1 and BRCA2 genes were discovered 25 years ago. Since then, our understanding of hereditary cancers has profoundly changed. The Basser Center was founded in 2012 to accelerate this understanding and propel progress in treatment opportunities and scientific discovery. Basser’s mission is to see a world free of the devastating effects of BRCA-related cancers.</Paragraph>
                        <Video css={videoStyles} image={imageData} ctaText="See Our Story" />
                    </ContentWrapper>
                </Section1>
            )
        }
        } />
    )
}

export default Section1Component;