import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import ContentWrapper from "../elements/content-wrapper"

import BigHeading from "../elements/big-heading"
import StickyHorizontal from "../elements/sticky-horizontal";
import DecorativeText from "../elements/decorative-text";

import backgroundImage from "../../images/2_Past/Timeline_Backbground.jpg";

import Img from 'gatsby-image'


const MomentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: flex-end;
`

const headingStyle = css`
    padding-top: 70px;
`

const baseImageCSS = css`
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: 30px;
`

const MomentFooter = styled.div`
    width: 100%;
    border-top: 2px solid ${props => props.theme.colors.grey};
    font-size: 50px;
    font-weight: 900;
    margin-top: 0px;
    color: ${props => props.theme.colors.blue};
    position: relative;
    &:before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 4px solid #FFF;
        background: ${props => props.theme.colors.grey};
        position: absolute;
        top: -10px;
    }
`

const MomentContent = styled.p`
    width: 100%;
    max-width: ${props => props.width};
    color: ${props => props.theme.colors.blue};
    font-size: 20px;
    padding-right: 50px;
    margin-top: 0px;
    margin-bottom: 40px;
    margin-right: auto;
    align-self: flex-end;
`

function Moment({ year, content, image, imageCSS, width }) {
  const theme = useTheme();
  return (
    <MomentWrapper>
      <Img css={[baseImageCSS, imageCSS]} fixed={image ? image.childImageSharp.fixed : ""} />
      <MomentContent width={width || "390px"} dangerouslySetInnerHTML={{ __html: content }} />
      <MomentFooter>
        {year}
      </MomentFooter>
    </MomentWrapper>
  )
}

function PastComponent(props) {

  const theme = useTheme();

  const Past = styled.section`
        background-color: #FFFFFF;
        padding: 0px;
        height: auto;
    `

  const Moments = styled.div`
        display: flex;
    `

  return (

    <StaticQuery query={graphql`
        query {
          researcher1: file(relativePath: {
            eq: "2_Past/Researcher_1.jpg"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 370) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          },
          clone: file(relativePath: {
            eq: "2_Past/BRCA_Gene.png"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          },
          ribbon: file(relativePath: {
            eq: "2_Past/Blue_Ribbon.png"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 130) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          },
          microscope: file(relativePath: {
            eq: "2_Past/Microscope.png"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          },
          pills: file(relativePath: {
            eq: "2_Past/Pills.png"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 400) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          },
          researcher2: file(relativePath: {
            eq: "2_Past/Researcher_2.jpg"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 350) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          },
          prize: file(relativePath: {
            eq: "Basser_Global_Prize.jpg"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          },
          check: file(relativePath: {
            eq: "2_Past/Checkmark.png"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 150) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          },
          pancreas: file(relativePath: {
            eq: "2_Past/Pancreas.jpg"
          }) {
            childImageSharp {
              fixed(quality: 75, width: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }`
    } render={data => {

      const Opening = styled.div`
                position: relative;
                margin-right: 95px;
                &:after {
                    content: "";
                    width: 95px;
                    height: 2px;
                    background: ${props => props.theme.colors.grey};
                    position: absolute;
                    bottom: 60px;
                    right: -95px;
                }
            
            `;

      const OpeningInner = styled.div`
                width: calc(100% + 60px);
                height: 320px;
                position: relative;
                margin-left: -30px;
                padding: 30px 40px;
                border: 1px solid #EB2036;
            `;

      const openingImage = css`
                margin-top: -80px;
            `

      const decorativeText = css`
                position: absolute;
                transform: rotate(-90deg);
                left: -300px;
                bottom: -125px;
                @media (min-width: ${theme.screens.mobile}) {
                  font-size: 175px;
                }
            `

      const boxShadow = css`
                box-shadow: ${theme.shadow};
            `

      const stickyInnerStyles = css`
                background-image: url('${backgroundImage}'); 
                background-size: 800px auto; 
                background-position: top right; 
                background-repeat: no-repeat;
            `

      return (
        <Past {...props}>
          <ContentWrapper>
            <BigHeading css={headingStyle} subheading="Past" heading="Where We Started" />
          </ContentWrapper>
          <StickyHorizontal heightShrinkOffset="0px" leftOffset="calc(50% - 150px)" contentWrapStyle={css`display: flex; align-items: center;`} stickyInnerStyle={stickyInnerStyles} endPadding={600}>
            <Moments>
              <Opening>
                <OpeningInner>
                  <DecorativeText css={decorativeText}>Past</DecorativeText>
                  <MomentContent css={css`padding: 0px;`}>
                    The journey to understanding <i>BRCA1</i> and <i>BRCA2</i> began with a spark. In 2019, we recognized the 25th anniversary of the first time researchers identified the complete sequence of BRCA1 and quantified the corresponding lifetime risks of breast and ovarian cancers.
                                    </MomentContent>
                </OpeningInner>
                <Img css={[boxShadow, openingImage]} fixed={data.researcher1.childImageSharp.fixed} />
              </Opening>
              <Moment width="240px" image={data.clone} content="<i>BRCA1</i> is cloned." year="1994" />
              <Moment content="The most common <i>BRCA1</i> mutation is traced back to families of Ashkenazi Jewish descent. Subsequently, two additional mutations — one in BRCA1 and one in BRCA2 — are identified as common Founder mutations in individuals of Ashkenazi Jewish descent. Mutations in BRCA1 and BRCA2 are seen in every race and ethnicity.<br /><br /><i>BRCA2</i> is cloned." year="1995" />
              <Moment width="250px" content="<i>BRCA2</i> mutations are linked to male breast cancer." year="1996" />
              <Moment width="250px" image={data.ribbon} year="1997" content="<i>BRCA2</i> mutations are linked to prostate cancer." />
              <Moment width="250px" image={data.microscope} year="1999" content="Research solidifies the role of <i>BRCA1</i> in homologous recombination." />
              <Moment year="2001" content="Bilateral preventive mastectomy is demonstrated to substantially reduce the incidence of breast cancer among women with mutations in <i>BRCA1</i> or <i>BRCA2</i>.<br /><br />The role of 53bp1 in regulating <i>BRCA1</i> is discovered. (Penn Medicine's Roger Greenberg, MD, PhD, is currently pursuing research in this critical area.)" />
              <Moment width="270px" image={data.pills} year="2005" content="PARP inhibitors are shown to kill cells that do not have <i>BRCA1</i> or <i>BRCA2</i> protein present." />
              <Moment width="270px" image={data.researcher2} imageCSS={[boxShadow, css`margin-left: -90px`]} year="2006" content="A paradigm-changing discovery by Basser Center’s Ronny Drapkin, MD, and colleagues revealed that the majority of high-grade serous ovarian cancers arise in the fallopian tubes. " />
              <Moment width="290px" year="2010" content="Research led by Basser Center Executive Director Susan M. Domchek, MD, showed strong evidence that removal of the ovaries improved overall survival in <i>BRCA1</i> and <i>BRCA2</i> mutation carriers." />
              <Moment width="260px" year="2012" content="Mindy and Jon Gray established the Basser Center for BRCA." />
              <Moment width="250px" image={data.prize} year="2013" content="Basser Global Prize is established and endowed by Shari and Len Potter." />
              <Moment width="470px" year="2014" content="FDA approved first PARP-inhibitor olaparib for BRCA-related ovarian cancer based on research led by the Basser Center. (PARP-inhibitors are a therapy that can stop cancerous cells from repairing themselves.)<br /><br />Evidence shows that PARP inhibitors may be helpful in <i>BRCA1/2</i>-related pancreatic and prostate cancer." />
              <Moment width="270px" image={data.check} year="2015" content="Basser Center research supported the FDA approval of rucaparib for BRCA-related ovarian cancer." />
              <Moment width="270px" year="2017" content="FDA approved niraparib for all ovarian cancers." />
              <Moment width="270px" image={data.pancreas} year="2018" imageCSS={boxShadow} content="Basser Center studies led to the FDA approval of olaparib and talazoparib for BRCA-related breast cancer." />
              <Moment width="270px" year="2019" content="Basser Center investigator Kim Reiss Binder, MD, showed that PARP Inhibitors are an effective and less toxic maintenance therapy for patients with BRCA-related pancreatic cancer.<br /><br />Olaparib is approved in first line maintenance for <i>BRCA1/2</i>-related ovarian cancer." />
            </Moments>
          </StickyHorizontal>
        </Past>
      )
    }
    } />
  )
}

/** year, content, image, imageCSS */

export default PastComponent;