import React from "react";
import { ThemeProvider } from 'emotion-theming';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled'

import fonts from "../fonts";

const theme = {
  fontFamily: 'Verlag',
  shadow: '0 30px 30px 0 rgba(0,0,0,0.3)',
  screens: {
    xl: '1280px',
    desktop: '1100px',
    tablet: '768px',
    mobile: '640px'
  },
  colors: {
    blue: "#002D72",
    darkBlue: "#002257",
    aqua: "#00a6d1",
    teal: "#127897",
    red: "#eb2036",
    black: "#000000",
    grey: "#777777",
    lightGrey: "#dddddd",
    offWhite: "#f7f7f7"
  }
}

const SiteWrapper = styled.div`
  width: 100%
`

const globalStyles = css`
  ${fonts}; 
  * { 
    box-sizing: border-box; 
  }; 
  i {
    font-style: italic;
  };
  html, body { 
    margin: 0px; font-family: ${theme.fontFamily} 
  };
  .mo {
    @media (min-width: ${theme.screens.mobile}) {
      display: none;
    }
  }
`

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
        <Global
          styles={globalStyles}
        />
      <SiteWrapper>
        {children}
      </SiteWrapper>
    </ThemeProvider>
  )
}
