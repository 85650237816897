import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import ContentWrapper from "../elements/content-wrapper"

import Heading from "../elements/heading"
import Separator from "../elements/separator"
import StickyHorizontal from "../elements/sticky-horizontal";
import Illustration from "../../images/1_Intro/Difference_Illustration.svg";
import SwipeyHorizontal from "../elements/swipey-horizontal";

function DollarsDifferenceComponent(props) {

    const theme = useTheme();

    const DollarsDifference = styled.section`
        background-color: #F7F7F7;
        height: auto;
        padding-bottom: 60px;
        @media (min-width: ${props => props.theme.screens.mobile}) {
          padding-bottom: 0px;
        }
    `

    const innerStyles = css`
        width: 200vw;
        height: 0px;
        background-size: contain;
        background-position: left center;
        padding-top: 26.98%;
        position: absolute;
        overflow: hidden;
    `

    const headingStyle = css`
      padding-top: 50px;
      @media (min-width: ${theme.screens.mobile}) {
        padding-top: 75px;
      }
    `

    const StatPopover = styled.div`
      background: ${props => props.theme.colors.teal};
      padding-left: 5vh;
      padding-right: 5vh;
      padding-top: 6vh;
      padding-bottom: 6vh;
      color: #FFF;
      display: inline-block;
      position: absolute;
      text-align: center;
      left: ${props => props.left};
      top: ${props => props.top};
      box-shadow: ${theme.shadow};
      &:after {
        content: "";
        width: 0; 
        height: 0; 
        position: absolute;
        right: -2vh;
        top: 60px;
        border-top: 2vh solid transparent;
        border-bottom: 2vh solid transparent;
        border-left: 2vh solid ${props => props.theme.colors.teal};
      }
    `

    const StatBigNumber = styled.h3`
      font-size: 10vh;
      font-weight: 200;
      margin: 0px;
      line-height: 100%;
    `

    const StatLabel = styled.p`
      font-size: 1.75vh;
      font-weight: bold;
      line-height: 125%;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin: 0px;
    `
    
    return ( 

        <StaticQuery query={graphql`
        query {
          illustration: file(relativePath: {
            eq: "1_Intro/Difference_Illustration.jpg"
          }) {
            childImageSharp {
              fluid(quality: 75, maxWidth: 4685, maxHeight: 1436) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }`
        } render={data => {
            const imageData = data.illustration.childImageSharp.fluid;

            const TheHeading =
              <>
                <ContentWrapper>
                  <Heading css={headingStyle}>How Your Dollars Have Made a Difference</Heading>
                  <Separator color="red"></Separator>
                </ContentWrapper>
              </>;

            return (
                <DollarsDifference {...props}>
                    <StickyHorizontal image={Illustration} heightShrinkOffset="300px" leftOffset="calc(50% - 250px)" contentWrapStyle={css`display: flex; align-items: center;`} above={TheHeading}>
                    </StickyHorizontal>
                    <SwipeyHorizontal above={TheHeading} image={Illustration} height="480px"></SwipeyHorizontal>
                </DollarsDifference>
            )
        }
        } />
    )
}

/** <BackgroundImage css={innerStyles} fluid={imageData}></BackgroundImage> */

export default DollarsDifferenceComponent;