import React from "react";
import styled from '@emotion/styled'

const ContentWrapper = styled.div`
  max-width: 1250px;
  margin: auto;
  padding: 0px 30px;
`

const ContentWrapperComponent = props => (
  <ContentWrapper {...props} />
)

export default ContentWrapperComponent;