import React from "react";
import styled from '@emotion/styled'
import { fluidRange } from 'polished';

const NameTitle = styled.div`
    background: #FFF;
    display: inline-block;
    width: 80%;
`

const Name = styled.h5`
    color: ${props => props.theme.colors.red};
    line-height: 140%;    
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin: 0px;
    ${props => fluidRange({
        prop: 'font-size',
        fromSize: '16px',
        toSize: props.isLarge ? `24px` : '18px',
    }, props.theme.screens.mobile, props.theme.screens.desktop)}
`

const Title = styled.h6`
    color: ${props => props.theme.colors.blue};
    width: 70%;
    font-weight: normal;
    margin: 5px 0px 0px 0px;
    line-height: 130%;
    ${props => fluidRange({
        prop: 'font-size',
        fromSize: '14px',
        toSize: '16px'
    }, props.theme.screens.mobile, props.theme.screens.desktop)}
`

const NameTitleComponent = props => (
  <NameTitle {...props}>
      <Name isLarge={props.size === "large" ? true : false} >{props.name}</Name>
      <Title dangerouslySetInnerHTML={{ __html: props.title }} />
  </NameTitle>
)

export default NameTitleComponent;