import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Masthead from "../components/sections/masthead";
import Section1 from "../components/sections/section1";
import Section2 from "../components/sections/section2";
import DollarsDifference from "../components/sections/dollarsDifference";
import ByTheNumbers from "../components/sections/byTheNumbers";
import Past from "../components/sections/past";
import Present from "../components/sections/present";
import Research1 from "../components/sections/research1";
import Research2 from "../components/sections/research2";
import Research3 from "../components/sections/research3";

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Basser 2019 Annual Report</title>
      </Helmet>
      <Masthead />
      <Section1 />
      <Section2 />
      <DollarsDifference />
      <ByTheNumbers />
      <Past />
      <Present /> 
      <Research1 />
      <Research2 />
      <Research3 />
    </Layout>
    )
}
