import React from "react";
import { StaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import ContentWrapper from "../elements/content-wrapper"
import Heading from "../elements/heading"
import Separator from "../elements/separator"
import RedBoxLg from "../elements/red-box-lg"
import ArticleHeading from "../elements/article-heading"
import Paragraph from "../elements/paragraph"
import Pullquote from "../elements/pullquote"
import Img from 'gatsby-image'

function Research1(props) {

    const theme = useTheme();

    const RedBoxInner = styled.div`
        display: flex;
        width: 100%;
        margin-left: 0px;
        flex-wrap: wrap;
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(100% + 220px);
            margin-left: -110px;
        }
        @media (min-width: ${theme.screens.xl}) {
            width: calc(100% + 300px);
            margin-left: -150px;
        }
    `

    const leftCol = css`
        width: 100%;
        @media (min-width: ${theme.screens.mobile}) {
            width: 50%;
            margin-left: 110px;
            margin-right: 65px;
        }
        @media (min-width: ${theme.screens.xl}) {
            margin-left: 150px;
        }
    `;

    const rightCol = css`
        width: 100%;
        margin-top: 130px;
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(50% - 25px);
            margin-top: 0px;
        }
    `;

    const drImage = css`
        box-shadow: ${theme.shadow};
        margin-right: -40px;
        margin-left: 60px;
        margin-bottom: 50px;
        @media (min-width: ${theme.screens.mobile}) {
            margin: 0px;
        }
    `

    const colWrap = css`
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (min-width: ${theme.screens.mobile}) {
            flex-wrap: nowrap;
        }
    `

    const pullquoteStyles = css`
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(100% - 110px);
            margin-left: 110px;
            margin-top: 150px;
        }
        @media (min-width: ${theme.screens.xl}) {
            width: calc(100% - 150px);
            margin-left: 150px;
        }
    `

    const redBox = css`
        max-width: 1040px;
        margin: auto;
    `

    const contentInner = css`
        @media (max-width: ${theme.screens.mobile}) {
            padding: 20px 20px 0px 20px;
        }
    `

    const articleHeading = css`
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(100% - 220px);
            margin-left: 110px;
        }
        @media (min-width: ${theme.screens.xl}) {
            width: calc(100% - 300px);
            margin-left: 150px;
        }
    `

    return (

        <StaticQuery query={graphql`
        query {
          dr: file(relativePath: {
            eq: "3_Present/Dr_Binder.jpg"
          }) {
            childImageSharp {
              fluid(quality: 75, maxWidth: 807) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }`
        } render={data => {
            const imageData = data.dr.childImageSharp.fluid;
            return (
                <ContentWrapper css={contentInner}>
                    <Heading>Our Key Research Breakthroughs</Heading>
                    <Separator color="red"></Separator>
                    <RedBoxLg css={redBox}>
                        <RedBoxInner>
                            <ArticleHeading css={articleHeading}>
                                PARP Inhibitor Trial for BRCA 1/2 and PALB2-Related Pancreatic Cancer
                            </ArticleHeading>
                            <div css={colWrap}>
                                <div css={leftCol}>
                                    <Paragraph>
                                        Pancreatic cancer is the third-leading cause of cancer deaths in the United States, and one of the most difficult cancers to treat. And while BRCA mutations are most closely associated with breast and ovarian cancers, they also lead to an increased risk for pancreatic cancer. The discovery of these relationships is already improving outcomes for affected patients. The Basser Center’s Kim Reiss Binder, MD, an Assistant Professor of Hematology-Oncology, is studying the use of PARP inhibitors for pancreatic cancer patients with a BRCA mutation.
                                        <br /><br />
                                        In 2019, Dr. Reiss Binder presented preliminary results from a clinical trial that showed that switching patients with incurable pancreatic cancer to the PARP inhibitor rucaparib as maintenance therapy instead of continuing intensive chemotherapy either shrunk tumors or stopped them from growing altogether. She is now preparing to launch a national trial to use this therapy in a frontline treatment setting, in the hope that it will offer cures to more patients. These discoveries represent on opportunity to change the face of BRCA-related pancreatic cancer, and give affected patients a chance at a better and longer life.
                                    </Paragraph>
                                </div>
                                <div css={rightCol}>
                                    <Img css={drImage} fluid={imageData} />
                                </div>
                            </div>
                            <Pullquote css={pullquoteStyles} name="KIM REISS BINDER, MD" title="Assistant Professor of Medicine">
                                To be able to offer a targeted therapy with much less toxicity, even if only for a subset of our patients, was an extraordinary breakthrough for our patients with metastatic pancreatic cancer.  Now we will apply this same therapy to patients with curable disease, in the hope that we can save more lives.
                            </Pullquote>
                        </RedBoxInner>
                    </RedBoxLg>

                </ContentWrapper>
            )
        }
        } />
    )
}

export default Research1;