import React from "react";
import styled from '@emotion/styled'
import Separator from "../elements/separator"
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer'

const Heading = styled(motion.h3)`
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 120%;
    text-align: center;
    margin: 0px 0px 10px 0px;
    color: ${props => props.theme.colors.blue};
    @media (min-width: ${props => props.theme.screens.mobile}) {
      font-size: 70px;
      margin: 10px 0px 10px 0px;
    }
`

const Subheading = styled(motion.h4)`
    color: ${props => props.theme.colors.red};
    font-weight: 900;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 4px;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
    @media (min-width: ${props => props.theme.screens.mobile}) {
        font-size: 20px;
    }
`

const HeadingComponent = props => { 
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true
      });
    
    return(
        <div {...props}>
            <Subheading transition={{duration: 1}} animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -15 }}>
                {props.subheading}
            </Subheading>
            <Heading ref={ref} transition={{duration: 1, delay: 0.15}} animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -15 }}>
                {props.heading}
            </Heading>
            <Separator color="red"></Separator>
        </div>
    )
}

export default HeadingComponent;