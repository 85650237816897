import styled from '@emotion/styled'
import { fluidRange } from 'polished';
import { motion } from "framer-motion";

const DecorativeText = styled(motion.h4)`
    color: black;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 2px;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #00A6D1;
    @media (min-width: ${props => props.theme.screens.mobile}) {
        font-size: 175px;
    }
    ${props => fluidRange({
        prop: 'font-size',
        fromSize: '70px',
        toSize: '175px'
      }, props.theme.screens.mobile, props.theme.screens.desktop)}
  
`

export default DecorativeText;