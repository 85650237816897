import React, { useState, useEffect, useRef } from "react";
import { css } from '@emotion/core'
import styled from '@emotion/styled'

const TallOuterContainer = styled.div`
  height: ${props => props.dynamicHeight}px;
  position: relative;
  width: 100%;
  display: none;
  @media (min-width: ${props => props.theme.screens.mobile}) {
    display: block;
  }
`;

const StickyInnerContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const HorizontalTranslateContainer = styled.div`
  transform: translateX(${props => props.translateX}px);
  position: absolute;
  height: calc(100% - ${props => props.heightShrinkOffset});
  will-change: transform;
  padding-left: ${props => props.leftOffset};
`;

const applyScrollListener = (ref, setTranslateX) => {
  if (ref && ref.current !== null) {
    window.addEventListener("scroll", () => {
      if (ref.current !== null) {
        const offsetTop = -ref.current.offsetTop;
        setTranslateX(offsetTop);
      }
    });
  }
};

export default ({ children, above, below, heightShrinkOffset, leftOffset, endPadding = 250, image, contentWrapStyle = "", stickyInnerStyle = "" }) => {
  const [dynamicHeight, setDynamicHeight] = useState(null);
  const [translateX, setTranslateX] = useState(0);

  const containerRef = useRef(null);
  const objectRef = useRef(null);

  const calcDynamicHeight = objectWidth => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    return objectWidth - vw + vh + endPadding;
  };  

  const handleDynamicHeight = (ref, setDynamicHeight) => {
    if (ref && ref.current !== null) {
      const objectWidth = ref.current.scrollWidth;
      const dynamicHeight = calcDynamicHeight(objectWidth);
      setDynamicHeight(dynamicHeight);
    }
  };  

  const resizeHandler = () => {
    handleDynamicHeight(objectRef, setDynamicHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    applyScrollListener(containerRef, setTranslateX);
    window.setTimeout(function () {
      handleDynamicHeight(objectRef, setDynamicHeight);
    }, 100);
  }, []);

  return (
    <TallOuterContainer dynamicHeight={dynamicHeight}>
      <StickyInnerContainer ref={containerRef} css={stickyInnerStyle}>
        {above}
        <HorizontalTranslateContainer css={contentWrapStyle} heightShrinkOffset={heightShrinkOffset} leftOffset={leftOffset} translateX={translateX} ref={objectRef}>
          {children}
          {image &&
            <img onLoad={resizeHandler} css={css`height: 100%; max-height: 700px`} src={image} />
          }
        </HorizontalTranslateContainer>
        {below}
      </StickyInnerContainer>
    </TallOuterContainer>
  );
};
