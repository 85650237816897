import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { fluidRange } from 'polished';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer'

import logoSVG from "../../images/Basser_Logo.svg";
import frameSVG from "../../images/1_Intro/frame.svg";
import frameMobileSVG from "../../images/1_Intro/mobile-frame.svg";

import ContentWrapper from "../elements/content-wrapper";
import BackgroundImage from 'gatsby-background-image';
import Separator from "../elements/separator";

function Masthead() {
  const theme = useTheme();

  const [ref, inView] = useInView();

  const section = css`
    display: "flex", 
    flexDirection: "column",
    transition: background 250ms;
    background-size: 600px auto!important;
    background-position: bottom center!important;
    @media (min-width: ${theme.screens.mobile}) {
      background-size: 1440px auto!important;
      background-position: center 210px!important;
      min-height: 645px!important;
    }
  `;

  const sectionPlainStyles = {
    backgroundSize: "inherit",
    backgroundPosition: "inherit"
  }

  const topHeading = css`
    line-height: 125%; 
    font-weight: normal;
    margin-top: 0px;
    ${fluidRange({
      prop: 'font-size',
      fromSize: '16px',
      toSize: '40px'
    }, theme.screens.mobile, theme.screens.desktop)}
    ${fluidRange({
      prop: 'margin-bottom',
      fromSize: '10px',
      toSize: '20px'
    }, theme.screens.mobile, theme.screens.desktop)}
    @media (min-width: ${theme.screens.mobile}) {
      margin-top: -30px;
    }
    `;

  const bottomHeading = css`
    text-transform: uppercase;
    font-weight: 900; 
    margin: 0px; 
    line-height: 140%;
    letter-spacing: 2px;
    @media (min-width: ${theme.screens.mobile}) {
      letter-spacing: 4px;
    }
    ${fluidRange({
      prop: 'font-size',
      fromSize: '14px',
      toSize: '28px'
    }, theme.screens.mobile, theme.screens.desktop)}`;

  const bigHeading = css`
    ${fluidRange({
      prop: 'font-size',
      fromSize: '65px',
      toSize: '110px'
    }, theme.screens.mobile, theme.screens.desktop)}
    font-size: 34px; 
    font-weight: 900;
    margin: 0px; 
    line-height: 100%; 
    text-transform: uppercase;
    `;

  const logo = css`
    margin: auto; 
    display: block;
    ${fluidRange({
      prop: 'width',
      fromSize: '100px',
      toSize: '200px'
    }, theme.screens.mobile, theme.screens.desktop)};
    ${fluidRange({
      prop: 'margin-top',
      fromSize: '35px',
      toSize: '80px'
    }, theme.screens.mobile, theme.screens.desktop)}
  `;

  const contentWrapper = css`
    height: 192px; 
    position: relative;
    @media (min-width: ${theme.screens.mobile}) {
      height: 480px; 
    }
  `;

  const innerContentWrapper = css`
    display: flex; 
    align-items: center; 
    justify-content: center;
    height: 100%;
    position: relative;
    bottom: -30px;
    @media (min-width: ${theme.screens.mobile}) {
      bottom: -50px;
    }
  `;

  const Frame = styled.div`
    width: 192px;
    height: 191px;
    bottom: 0px;
    display: block;
    position: absolute; 
    z-index: 0;
    background-image: url('${frameMobileSVG}');
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 250ms;
    @media (min-width: ${theme.screens.mobile}) {
      background-image: url('${frameSVG}');
      width: 532px;
      height: 480px;  
    } 
  `;

  const separator = css`
    margin: 10px auto 15px auto;
    @media (min-width: ${theme.screens.mobile}) {
      margin: 20px auto 24px auto
    }
  `;

  return (
    <StaticQuery query={graphql`
    query {
      hero: file(relativePath: {
        eq: "1_Intro/Basser_DNA_Hero.jpg"
      }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }`
    } render={data => {
      const imageData = data.hero.childImageSharp.fluid;
      return (
        <>
          <BackgroundImage Tag="section" fluid={imageData} css={section} style={sectionPlainStyles}>
            <img css={logo} src={logoSVG} alt="Logo" />
            <ContentWrapper css={contentWrapper}>
              <div css={innerContentWrapper}>
                <div css={css`position: relative; z-index: 1; text-align: center; color: ${theme.colors.blue};`}>
                  <motion.h3 ref={ref} transition={{ delay: 0.5, duration: 1 }} animate={{ scale: 1, opacity: 1 }} initial={{ scale: 0.75, opacity: 0 }} css={topHeading}>25 Years of <br/>Transformational BRCA</motion.h3>
                  <motion.h1 css={bigHeading} transition={{ delay: 1, duration: 1 }} animate={{ scale: 1, opacity: 1}} initial={{ scale: 0.95, opacity: 0}}>Breakthroughs</motion.h1>
                  <Separator animate={{ scaleY: 1, opacity: 1, y: 0 }} initial={{ scaleY: 0.95, opacity: 0, y: -10 }} transition={{delay: 1}} color="red" css={separator} />
                  <motion.h2 transition={{ delay: 1.5, duration: 1 }} animate={{ scale: 1, opacity: 1, y: 0 }} initial={{ scale: 0.95, opacity: 0, y: -10 }} css={bottomHeading}>2019 <br className="mo" />Progress Report</motion.h2>
                </div>
                <Frame />
              </div>
            </ContentWrapper>
          </BackgroundImage>
        </>
      )
    }
    } />
  )
}

export default Masthead;
