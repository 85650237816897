import React from "react";
import styled from '@emotion/styled'

const RedBoxLg = styled.div`
    border: 1px solid ${props => props.theme.colors.red};
    padding: 20px;
    position: relative;
    z-index: 2;
    background: #FFF;
    @media (min-width: ${props => props.theme.screens.mobile}) {
        padding: 80px;
    }
`

const RedBoxLgComponent = props => (
  <RedBoxLg {...props} />
)

export default RedBoxLgComponent;