import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import ContentWrapper from "../elements/content-wrapper"
import BigHeading from "../elements/big-heading";
import DecorativeText from "../elements/decorative-text";
import Paragraph from "../elements/paragraph";
import Carousel from "re-carousel"
import IndicatorDots from '../elements/carousel-indicator-dots'
import Buttons from '../elements/carousel-buttons'
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer'

const Present = styled.section`
    padding-top: 150px;
    padding-bottom: 200px;
    margin-bottom: 100px;
    @media (min-width: 825px) {
        padding-bottom: 140px;
        margin-bottom: 0px;
    }
`

const PresentContent = styled.div`
    border: 1px solid #EB2036;
    padding: 30px;
    background: #FFFFFF;
    width: 70%;
    max-width: 450px;
    margin: -5% auto auto auto;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (min-width: ${props => props.theme.screens.mobile}) {
        width: 100%;
    }
`

const Achievement = styled.div`
    width: 235px;
    height: 235px;
    margin: auto;
    border-radius: 50%;
    text-align: center;
    background: ${props => props.theme.colors.blue};
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`

const AchievementWrapper = styled.div`
    width: calc(100vw - 30px);
    max-width: 825px;
    justify-content: space-between;
    flex-shrink: 0;
    position: relative;
    bottom: 10px;
    display: none;
    overflow: hidden;
    @media (min-width: 825px) {
        display: flex;
    }
`

const AchievementCarousel = styled.div`
    width: 100%;
    height: 235px;
    position: absolute;
    bottom: -200px;
    @media (min-width: 825px) {
        display: none;
    }
`

const achievementName = css`
    font-size: 50px;
    line-height: 100%;
    font-weight: 900;
    margin: 0px 0px;
`

const achievementDescription = css`
    font-size: 20px;
    line-height: 130%;
    font-weight: normal;
    margin: 5px 0px 0px 0px;
    padding: 0px 10px;
`

const presentCopy = css`
    text-align: center;
`

function PresentComponent(props) {
    const theme = useTheme();

    const [ref, inView] = useInView({threshold: 0.5});

    const presentSubheading = css`
        font-size: 16px;
        line-height: 140%;
        color: ${theme.colors.red};
        text-align: center;
        margin: 20px 0px 20px 0px;
        @media (min-width: ${theme.screens.mobile}) {
            font-size: 24px;
            margin: 50px 0px 40px 0px;
        }
    `

    const AchievementComponent = props => (
        <Achievement>
            <div>
                <h6 css={achievementName} dangerouslySetInnerHTML={{
                    __html: props.name
                }} />
                <p css={achievementDescription} dangerouslySetInnerHTML={{__html: props.description}} />
            </div>
        </Achievement>
    )

    return (
        <Present ref={ref}>
            <ContentWrapper>
                <BigHeading subheading="Present" heading="New in 2019" />
                <DecorativeText transition={{duration: 1}} animate={inView ? { y: 0, opacity: 1 } : { y: 50, opacity: 0 }}>Present</DecorativeText>
                <PresentContent>

                    <Paragraph css={presentCopy}>The Basser Center continues to extend the reach of its top researchers and clinicians by constantly innovating and collaborating to bring the best care possible to patients around the world.</Paragraph>
                    <h5 css={presentSubheading}>In 2019 alone, Basser has: </h5>

                    <AchievementWrapper>
                        <AchievementComponent name="20" description="Funded Research<br>Projects" />
                        <AchievementComponent name="6" description="External<br>Grants Awarded" />
                        <AchievementComponent name="$12M" description="Raised From Over<br>1,000 Donors" />
                    </AchievementWrapper>

                    <AchievementCarousel>
                        <Carousel auto loop widgets={[IndicatorDots, Buttons]}>
                            <AchievementComponent name="20" description="Funded Research<br>Projects" />
                            <AchievementComponent name="6" description="External<br>Grants Awarded" />
                            <AchievementComponent name="$12M" description="Raised From Over<br>1,000 Donors" />
                        </Carousel>
                    </AchievementCarousel>

                </PresentContent>
            </ContentWrapper>
        </Present>
    )
}

export default PresentComponent;