import React from "react";
import { StaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import ContentWrapper from "../elements/content-wrapper"
import Heading from "../elements/heading"
import Separator from "../elements/separator"
import RedBoxLg from "../elements/red-box-lg"
import ArticleHeading from "../elements/article-heading"
import Paragraph from "../elements/paragraph"
import Pullquote from "../elements/pullquote"
import Img from 'gatsby-image'

function Research1(props) {

    const theme = useTheme();

    const RedBoxInner = styled.div`
        display: flex;
        width: 100%;
        margin-left: 0px;
        flex-wrap: wrap;
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(100% + 220px);
            margin-left: -110px;
        }
        @media (min-width: ${theme.screens.xl}) {
            width: calc(100% + 300px);
            margin-left: -150px;
        }
    `

    const leftCol = css`
        width: 100%;
        @media (min-width: ${theme.screens.mobile}) {
            width: 50%;
            margin-left: 110px;
            margin-right: 65px;
        }
        @media (min-width: ${theme.screens.xl}) {
            margin-left: 150px;
        }
    `;

    const rightCol = css`
        width: 100%;
        margin-top: 130px;
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(50% - 25px);
            margin-top: 135px;
        }
    `;

    const drImage = css`
        box-shadow: ${theme.shadow};
        margin-right: -40px;
        margin-left: 60px;
        margin-bottom: 50px;
        @media (min-width: ${theme.screens.mobile}) {
            margin: 0px;
        }
    `

    const colWrap = css`
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (min-width: ${theme.screens.mobile}) {
            flex-wrap: nowrap;
        }
    `

    const pullquoteStyles = css`
        @media (min-width: ${theme.screens.mobile}) {
            width: calc(100% - 110px);
            margin-left: 110px;
            margin-top: 150px;
        }
        @media (min-width: ${theme.screens.xl}) {
            width: calc(100% - 150px);
            margin-left: 150px;
        }
    `

    const redBox = css`
        max-width: 1040px;
        margin: -30px auto auto auto;
        @media (min-width: ${theme.screens.mobile}) {
            margin-top: -216px;
        }
    `

    const contentInner = css`
        @media (max-width: ${theme.screens.mobile}) {
            padding: 0px 20px;
        }
    `

    const articleHeading = css`
    `

    return (

        <StaticQuery query={graphql`
        query {
          lab: file(relativePath: {
            eq: "3_Present/Lab.jpg"
          }) {
            childImageSharp {
              fluid(quality: 75, maxWidth: 807) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }`
        } render={data => {
            const imageData = data.lab.childImageSharp.fluid;
            return (
                <ContentWrapper css={contentInner}>
                    <RedBoxLg css={redBox}>
                        <RedBoxInner>
                            <div css={colWrap}>
                                <div css={leftCol}>
                                    <ArticleHeading css={articleHeading}>
                                        Combination Approaches to Treat BRCA-Related Breast Cancer
                                    </ArticleHeading>
                                    <Paragraph>
                                        Basser Center Executive Director Susan M. Domchek, MD, led a multicenter study evaluating the combination of the drugs olaparib and durvalumab in patients with germline (inherited) BRCA-related breast and ovarian cancer with advanced disease. The study results found that this combination shows promise. This research will be published in The Lancet Oncology 2020.
                                        <br /><br />
                                        “Patients with responses showed durable benefit,” Dr. Domchek says of the breast cancer cohort, “and progression-free survival and overall survival compared well with standard-of-care therapy for these populations.” While additional analysis is needed to determine which types of patients would benefit most from these combination therapies, it offers the hope of new viable and less toxic treatment options for patients.
                                        <br /><br />
                                        An additional study of individuals with advanced <i>BRCA1/2</i> cancer of many types treated with avelumab (an immune therapy) and talazoparib (a PARP inhibitor) has just closed to accrual and final results are being eagerly awaited.
                                    </Paragraph>
                                </div>
                                <div css={rightCol}>
                                    <Img css={drImage} fluid={imageData} />
                                </div>
                            </div>
                            <Pullquote css={pullquoteStyles} name="SUSAN M. DOMCHEK, MD" title="Assistant Professor of Medicine">
                            The hope is that by combining these two therapies we are able to prolong a patient’s response, helping them fight their disease longer.</Pullquote>
                        </RedBoxInner>
                    </RedBoxLg>

                </ContentWrapper>
            )
        }
        } />
    )
}

export default Research1;