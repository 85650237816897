import { css } from '@emotion/core'

import VerlagBlack from '../static/fonts/Verlag-Black_web.woff';
import VerlagBold from '../static/fonts/Verlag-Bold_web.woff';
import VerlagBook from '../static/fonts/Verlag-Book_web.woff';
import VerlagBookItalic from '../static/fonts/Verlag-BookItalic_web.woff';
import VerlagLight from '../static/fonts/Verlag-Light_web.woff';
import VerlagLightItalic from '../static/fonts/Verlag-LightItalic_web.woff';

const fonts = css`
  @font-face {
      font-family: 'Verlag';
      src: local('Verlag'),
      url(${VerlagBlack}) format('woff');
      font-weight: 900;
      font-style: normal;
  }
  @font-face {
      font-family: 'Verlag';
      src: local('Verlag'),
      url(${VerlagBook}) format('woff');
      font-weight: 400;
      font-style: normal;
  }
  @font-face {
      font-family: 'Verlag';
      src: local('Verlag'),
      url(${VerlagBookItalic}) format('woff');
      font-weight: 400;
      font-style: italic;
  }
  @font-face {
      font-family: 'Verlag';
      src: local('Verlag'),
      url(${VerlagBold}) format('woff');
      font-weight: 700;
      font-style: normal;
  }
  @font-face {
      font-family: 'Verlag';
      src: local('Verlag'),
      url(${VerlagLight}) format('woff');
      font-weight: 300;
      font-style: normal;
  }
  @font-face {
      font-family: 'Verlag';
      src: local('Verlag'),
      url(${VerlagLightItalic}) format('woff');
      font-weight: 300;
      font-style: italic;
  }
`;

export default fonts;
