import React from "react";
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import playButtonIcon from "../../images/play.svg";

const Button = styled.div`
    line-height: 40px;
    height: 40px;
    padding: 0px 30px;
    border: 2px solid #FFF;
    border-radius: 40px;
    text-transform: uppercase;
    display: inline-flex;
    font-weight: 900;
    color: #FFFFFF;
    cursor: pointer;
    align-items: center;
    &:hover {
        border-color: ${props => props.theme.colors.aqua};
        color: ${props => props.theme.colors.aqua};
    }
    &:active {
        background: ${props => props.theme.colors.darkBlue};
    }
`

const ButtonComponent = props => (
    <Button {...props}>
        <span>{props.ctaText}</span>
        {props.type === "video" && (
            <img css={css`margin-left: 5px`} alt="Play" src={playButtonIcon} />
        )}
    </Button>
)

export default ButtonComponent;