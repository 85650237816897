import React from "react"
import Modal from 'react-modal'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import BackgroundImage from 'gatsby-background-image'
import Button from '../elements/button'
import { useTheme } from 'emotion-theming'

import closeIcon from "../../images/close.svg";

function VideoComponent(props) {
    const theme = useTheme();

    Modal.setAppElement('#___gatsby');

    const Video = styled.div`
        width: 100%;
        max-width: 807px;
        cursor: pointer
    `

    const videoStyles = css`
        width: 100%;
        height: 0px;
        overflow: hidden;
        position: relative;
        padding-bottom: 56.25%;
        display: flex;
        justify-content: center;
    `

    const buttonStyles = css`
        position: absolute;
        bottom: 20px;
        @media (min-width: ${theme.screens.mobile}) {
            bottom: 15%;
        }
    `

    const iframeStyles = css`
        position: absolute;
        width: 100%;
        height: 100%;
    `

    const modalStyles = css`
        width: 100%;
        max-width: 800px;
        margin: auto;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 30px;
    `

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Video Modal"
                css={modalStyles}
                style={{
                    overlay: {
                        display: "flex",
                        zIndex: 99
                    }
                }}
            >
                <img src={closeIcon} css={css`width: 20px; position: absolute; left: 10px; top: 0px; cursor: pointer;`} onClick={closeModal} />
                <div css={videoStyles}>
                    <iframe css={iframeStyles} src="https://www.youtube.com/embed/DqrFyS_AFr8?autoplay=true" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Modal>

            <Video {...props} onClick={openModal}>
                <BackgroundImage css={videoStyles} fluid={props.image}>
                    <Button css={buttonStyles} ctaText={props.ctaText} type="video" />
                </BackgroundImage>
            </Video>
        </>
    )
}

export default VideoComponent;