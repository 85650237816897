import React from "react";
import styled from '@emotion/styled'
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer'

const SeparatorWrapper = styled(motion.div)`
    width: 90px;
    display: flex;
    transition: all 250ms;
    margin: auto auto 30px auto;
    @media (min-width: ${props => props.theme.screens.mobile}) {
        width: 172px;
        margin: auto auto 50px auto;
    }
    & span {
        width: 33.33%;
        height: 3px;
        @media (min-width: ${props => props.theme.screens.mobile}) {
            height: 5px;
        }
        &:first-of-type {
            background: ${props => props.color === "red" ? '#B20838' : '#00A6D1'};
        }
        &:nth-of-type(2) {
            background: ${props => props.color === "red" ? '#F4836C' : '#127897'};
        }
        &:nth-of-type(3) {
            background: ${props => props.color === "red" ? '#EC1B2E' : '#76CEE0'};
        }
    }
`

const Separator = props => {
    const [ref, inView] = useInView({
        triggerOnce: true
      });

    const animate = props.animate || inView ? { scaleY: 1, opacity: 1 } : { scaleY: 0, opacity: 0 };
    const initial = props.initial || false;
    const transition = props.transition || { delay: 0.25, type: 'tween' };

    return  (
        <SeparatorWrapper ref={ref} transition={transition} animate={animate} inital={initial} color={props.color} {...props}> 
            <span></span>
            <span></span>
            <span></span>
        </SeparatorWrapper>
    )
}

export default Separator;