import React, { useRef } from "react";
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { motion } from "framer-motion"
import rightArrow from "../../images/right.svg";

const SwipeIndicator = styled.h5`
  position: absolute;
  margin: 0px;
  bottom: 0px;
  right: 20px;
  color: ${props => props.theme.colors.blue};
`

const SwipeyOuterContainer = styled.div`
  width: 100%;
  height: calc(${props => props.height} + 20px);
  position: relative;
  overflow: hidden;
`;

const SwipeySection = styled.section`
  @media (min-width: ${props => props.theme.screens.mobile}) {
    display: none;
  }
`

const swipeyContainer = css`
  transform: translateX(${props => props.translateX}px);
  height: calc(100% - 20px);
  will-change: transform;
  padding-left: ${props => props.leftOffset};
  user-select: none;
  position: absolute;
`;

export default ({ children, above, below, image, contentWrapStyle = "", height }) => {

  const containerRef = useRef(null);

  return (
    <SwipeySection>
      {above}
      <SwipeyOuterContainer ref={containerRef} height={height}>
        <motion.div css={[contentWrapStyle, swipeyContainer]} drag="x" dragConstraints={containerRef}>
          {children}
          {image &&
            <img draggable="false" css={css`height: 100%; pointer-events: none;`} src={image} />
          }
        </motion.div>
        <SwipeIndicator>SWIPE <img src={rightArrow} /></SwipeIndicator>
      </SwipeyOuterContainer>
      {below}
    </SwipeySection>
  );
};
