import React from "react";
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import NameTitle from "../elements/name-title"
import { fluidRange } from 'polished';

const Pullquote = styled.blockquote`
    font-weight: 300;
    font-style: italic;
    font-size: 24px;
    line-height: 130%;
    color: ${props => props.theme.colors.blue};
    margin: 0px;
    background: #FFF;
    ${props => fluidRange({
        prop: 'font-size',
        fromSize: '24px',
        toSize: '45px'
      }, props.theme.screens.mobile, props.theme.screens.desktop)};
    &:before, &:after {
        font-weight: 900;
        color: ${props => props.theme.colors.red};
    }
    &:before {
        content: "“";
    }
    &:after {
        content: "”";
    }
`

const nameTitle = css`
    margin-top: 40px;
`

const PullquoteComponent = props => (
<div {...props} >
  <Pullquote>
      {props.children}
  </Pullquote>
  {props.name &&
    <NameTitle css={nameTitle} name={props.name} title={props.title} size={props.size || "small"} />
  }
</div>
)

export default PullquoteComponent;