import React from "react";
import styled from '@emotion/styled'
import { fluidRange } from 'polished';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer'

const Heading = styled(motion.h3)`
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 120%;
    text-align: center;
    margin: 0px 0px 10px 0px;
    color: ${props => props.theme.colors.blue};
    ${props => fluidRange({
      prop: 'font-size',
      fromSize: '30px',
      toSize: '50px'
    }, props.theme.screens.mobile, props.theme.screens.desktop)};
    @media (min-width: ${props => props.theme.screens.mobile}) {
      margin: 0px 0px 30px 0px;
    }
`

const HeadingComponent = props => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  return (
    <Heading ref={ref} transition={{duration: 1}} animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -15 }} {...props} />
  )
}

export default HeadingComponent;