import React from "react";
import styled from '@emotion/styled'
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer'

const Paragraph = styled(motion.p)`
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    color: ${props => props.theme.colors.blue};
    @media (min-width: ${props => props.theme.screens.mobile}) {
      font-size: 20px;
      line-height: 30px;
    }
`

const ParagraphComponent = props => {
  
  const [ref, inView] = useInView({
    threshold: 0.5
  });

  const animate = inView ? props.animateTo : props.animateFrom;
  const transition = props.transition || { delay: 0.15, type: 'tween' };

  return (
    <Paragraph ref={ref} animate={animate} transition={transition} initial={props.ParagraphComponentanimateFrom} {...props} />
  )
}

export default ParagraphComponent;