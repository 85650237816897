import styled from '@emotion/styled'

const Heading = styled.h3`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 120%;
    text-align: left;
    margin: 0px 0px 20px 0px;
    color: ${props => props.theme.colors.blue};
    @media (min-width: ${props => props.theme.screens.mobile}) {
      margin: 0px 0px 50px 0px;
      font-size: 34px;
    }
`

export default Heading;