import React from "react";
import { StaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import BackgroundImage from 'gatsby-background-image';
import Paragraph from "../elements/paragraph"

function Research2(props) {
    const theme = useTheme();

    const insightSection = css`
        width: 100%;
        height: 340px;
        margin-top: -30px;
        background-color: #EFF1F0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: ${theme.screens.mobile}) {
          height: 800px;
          margin-top: -80px;
          &:before {
            background-attachment: fixed;
            background-size: cover;
          }
        }
    `;

    const insightPlain = {
      backgroundSize: "150% auto"
    }  

    const Insight = styled.div`
        margin: 0px 90px;
        @media (min-width: ${theme.screens.mobile}) {
          width: 500px;
          margin-top: -112.5px;
        }
    `;

    const InsightHeading = styled.h5`
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 2px;
        color: #002D72;
        text-transform: uppercase;
        margin: 0px 0px 20px 0px;
    `;

    const insightParagraph = css`
        @media (min-width: ${theme.screens.mobile}) {
            font-size: 30px;
            line-height: 40px;
            font-weight: 200;
        }
    `

    return (
        <StaticQuery query={graphql`
        query {
          background: file(relativePath: {
            eq: "3_Present/Pill_Background.jpg"
          }) {
            childImageSharp {
              fluid(quality: 75, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }`
        } render={data => {
          const imageData = data.background.childImageSharp.fluid;
          return (
            <BackgroundImage fluid={imageData} style={insightPlain} css={insightSection}>
                <Insight>
                    <InsightHeading>Research Insight</InsightHeading>
                    <Paragraph css={insightParagraph}>The PARP Inhibitor study investigates whether patients can safely discontinue intravenous chemotherapy and, instead, keep their cancer in check with a pill.</Paragraph>
                </Insight>
            </BackgroundImage>
          )
        }} />
    )
}

export default Research2;