import React from 'react'
import propTypes from 'prop-types'
import left from "../../images/black-left.svg";
import right from "../../images/black-right.svg";
import { css } from '@emotion/core';

const arrow = css`
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: 0px;
    cursor: pointer;
`

const wrapper = css`
    position: absolute;
    width: 100%;
    z-index: 100;
    text-align: center;
    top: 0px;
    bottom: 0px;
    height: 27px;
    margin: auto;
`

export default function Buttons (props) {
  const { index, total, loop, prevHandler, nextHandler } = props
  return (
    <div css={wrapper}>
      { (loop || index !== 0) && (
        <img css={[arrow, css`left: -30px;`]} src={left} onClick={prevHandler} />
      )}
      { (loop || index !== total - 1) && (
        <img css={[arrow, css`right: -30px;`]} src={right} onClick={nextHandler} />
      )}
    </div>
  )
}

Buttons.propTypes = {
  index: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  prevHandler: propTypes.func,
  nextHandler: propTypes.func
}
